<template>
    <div class="opacity-page table">
        <div class="row-space-tbf list-dashboard">
			<div class="space-left"></div>
			<div class="content dashboard">
				<div class="items dashboard">
					<div class="item">
						<div class="container">
							<div class="icon">
								<img src="/build/images/contracts.png">
							</div>
							<div class="details">
								<div class="label">
									{{ $t('salary.dashboard.contracts_number') }}
								</div>
								<div class="value">
									{{dashboard.active_contracts_count}}
								</div>
							</div>
						</div>
					</div>
					<div class="item second">
						<div class="container">
							<div class="icon">
								<img src="/build/images/users.png">
							</div>
							<div class="details">
								<div  class="label">
									{{ $t('salary.dashboard.number_average_employees') }}
								</div>
								<div  class="value">
                                    {{dashboard.average_number_of_employees}}
								</div>
							</div>
						</div>
					</div>
					<div class="item third">
						<div class="container">
							<div class="icon">
								<img src="/build/images/money.png">
							</div>
							<div class="details">
								<div class="label">
									{{ $t('salary.dashboard.average_medium_salary') }}
								</div>
								<div class="value">
                                    {{dashboard.average_gross_salary ? dashboard.average_gross_salary.toLocaleString() : ''}}
								</div>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="container">
							<div class="icon">
								<img src="/build/images/salary.png">
							</div>
							<div class="details">
								<div class="label">
									{{ $t('salary.dashboard.average_medium_net') }}
								</div>
								<div class="value">
                                    {{dashboard.average_net_salary ? dashboard.average_net_salary.toLocaleString() : ''}}
								</div>
							</div>
						</div>
					</div>
				</div>
                <div class="single-table">
                    <div class="container-dashboard">
                        <section class="table-section">
                            <div class="dashboard-table">
                                <div class="header-dashboard">
                                    <div class="column header">
                                        <div class="header-name">{{ $t('salary.dashboard.working_days') }}</div>
                                    </div>
                                    <div class="column header">
                                        <div class="header-name">{{ $t('salary.dashboard.number_employees') }}</div>
                                    </div>
                                    <div class="column header">
                                        <div class="header-name">{{ $t('salary.dashboard.total_employer_cost') }}</div>
                                    </div>
                                    <div class="column header">
                                        <div class="header-name">{{ $t('salary.dashboard.total_net_salary') }}</div>
                                    </div>
                                    <div class="column header">
                                        <div class="header-name">{{ $t('salary.dashboard.cas') }}</div>
                                    </div>
                                    <div class="column header">
                                        <div class="header-name">{{ $t('salary.dashboard.cass') }}</div>
                                    </div>
                                    <div class="column header">
                                        <div class="header-name">{{ $t('salary.dashboard.tax') }}</div>
                                    </div>
                                    <div class="column header">
                                        <div class="header-name">{{ $t('salary.dashboard.cas_employer') }}</div>
                                    </div>
                                    <div class="column header">
                                        <div class="header-name">{{ $t('salary.dashboard.cam') }}</div>
                                    </div>
                                    <div class="column header">
                                        <div class="header-name">{{ $t('salary.dashboard.disability_fund_amount') }}</div>
                                    </div>
                                    <div class="column header">
                                        <div class="header-name">{{ $t('salary.dashboard.tickets') }}</div>
                                    </div>
                                </div>
                                <div class="data-dashboard">
                                    <div v-if="dashboard.total_amounts" class="row-data">
                                        <div class="column header">
                                            <div class="data">{{dashboard.total_amounts.working_days}}</div>
                                        </div>
                                        <div class="column header">
                                            <div class="data">{{dashboard.total_amounts.employees_number}}</div>
                                        </div>
                                        <div class="column header">
                                            <div class="data">{{dashboard.total_amounts.total_gross_salary}}</div>
                                        </div>
                                        <div class="column header">
                                            <div class="data">{{dashboard.total_amounts.net_salary}}</div>
                                        </div>
                                        <div class="column header">
                                            <div class="data">{{dashboard.total_amounts.social_insurance_amount}}</div>
                                        </div>
                                        <div class="column header">
                                            <div class="data">{{dashboard.total_amounts.health_insurance_amount}}</div>
                                        </div>
                                        <div class="column header">
                                            <div class="data">{{dashboard.total_amounts.tax}}</div>
                                        </div>
                                        <div class="column header">
                                            <div class="data">{{dashboard.total_amounts.employer_social_insurance}}</div>
                                        </div>
                                        <div class="column header">
                                            <div class="data">{{dashboard.total_amounts.cam_amount}}</div>
                                        </div>
                                        <div class="column header">
                                            <div class="data">{{dashboard.total_amounts.disability_fund_amount}}</div>
                                        </div>
                                        <div class="column header">
                                            <div class="data">{{dashboard.total_amounts.ticket_amount}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div class="charts">
                    <div class="chart left-chart">
                        <apexchart :width="$resize && $mq.below(599) ? 270 : width" height="350" class="donut" type="donut" :options="optionsFirst" :series="seriesFirst"></apexchart>
                    </div>
                    <div class="chart right-chart">
                        <apexchart :width="$resize && $mq.below(599) ? 270 : width" class="donut" type="donut" :options="optionsSecond" :series="seriesSecond"></apexchart>
                    </div>
                </div>
                <div class="twin-table">
                    <div class="table left-table">
                        <div class="label">
                            {{ $t('salary.dashboard.new_emplyers') }}
                        </div>
                        <div v-if="dashboard.contracts_start_in_month && dashboard.contracts_start_in_month.length > 0" class="container-dashboard">
                            <section class="table-section">
                                <div class="dashboard-table">
                                    <div class="header-dashboard">
                                        <div class="column header">
                                            <div class="header-name">{{ $t('salary.dashboard.name') }}</div>
                                        </div>
                                        <div class="column header">
                                            <div class="header-name">{{ $t('salary.dashboard.mark') }}</div>
                                        </div>
                                        <div class="column header">
                                            <div class="header-name">{{ $t('salary.dashboard.date_employe') }}</div>
                                        </div>
                                    </div>
                                    <div v-if="dashboard.contracts_start_in_month" class="data-dashboard">
                                        <div v-for="data in dashboard.contracts_start_in_month" class="row-data">
                                            <div class="column header">
                                                <div class="data">{{data.name}}</div>
                                            </div>
                                            <div class="column header">
                                                <div class="data">{{data.marca}}</div>
                                            </div>
                                            <div class="column header">
                                                <div class="data">{{data.first_day_of_activity | moment('DD MMM YYYY')}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="no-data" v-else>
                            {{ $t('salary.dashboard.no_new_contracts') }}
                        </div>
                    </div>
                    <div class="table right-table">
                        <div class="label">
                            {{ $t('salary.dashboard.leave_emplyers') }}
                        </div>
                        <div v-if="dashboard.contracts_end_in_month && dashboard.contracts_end_in_month.lenght > 0" class="container-dashboard">
                            <section class="table-section">
                                <div class="dashboard-table">
                                    <div class="header-dashboard">
                                        <div class="column header">
                                            <div class="header-name">{{ $t('salary.dashboard.name') }}</div>
                                        </div>
                                        <div class="column header">
                                            <div class="header-name">{{ $t('salary.dashboard.mark') }}</div>
                                        </div>
                                        <div class="column header">
                                            <div class="header-name">{{ $t('salary.dashboard.date_employe') }}</div>
                                        </div>
                                    </div>
                                    <div v-if="dashboard.contracts_end_in_month" class="data-dashboard">
                                        <div v-for="data in dashboard.contracts_end_in_month" class="row-data">
                                            <div class="column header">
                                                <div class="data">{{data.name}}</div>
                                            </div>
                                            <div class="column header">
                                                <div class="data">{{data.marca}}</div>
                                            </div>
                                            <div class="column header">
                                                <div class="data">{{data.last_day_of_activity | moment('DD MMM YYYY')}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="no-data" v-else>
                            {{ $t('salary.dashboard.no_contracts_leaves') }}
                        </div>
                    </div>
                </div>
			</div>
			<div class="space-right"></div>
        </div>
    </div>
</template>

<script>
	import IconPlus from '../../../Icons/Plus'
	import IconMinus from '../../../Icons/Minus'

	export default {
		components: {
			IconPlus,
			IconMinus
		},
		data() {
			return {
				loaded: false,
                dashboard:{},
                width: 400,
                optionsFirst: {
                    labels: ["<18", "18-30", "31-45", "46-65", ">65"],
                    offsetY: 0,
                    title: {
                        text: "Personal pe categorii de varsta"
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        show: true,
                                        fontSize: '22px',
                                        fontFamily: 'Rubik',
                                        color: '#dfsda',
                                        offsetY: -10
                                    },
                                    value: {
                                        show: true,
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica, Arial, sans-serif',
                                        color: undefined,
                                        offsetY: 0,
                                            formatter: function (val) {
                                                return val
                                            }
                                    },
                                    total: {
                                        show: true,
                                        label: 'Media',
                                        color: '#373d3f',
                                        formatter: function (w) {
                                            return w.globals.seriesTotals.reduce((a, b) => a + b) / w.globals.seriesTotals.length;
                                        }
                                    },
                                },
                            },
                        },
                    },
                    legend: {
                        position: 'left',
                        offsetY: 20,
                        height: 230,
                         formatter: function(label, opts) {
                            return label + " - " + opts.w.globals.series[opts.seriesIndex]
                        }
                    }
                },
                seriesFirst: [],
                optionsSecond: {
                    labels: ["Femei", "Barbati"],
                    title: {
                        text: "Personal pe categorii de varsta"
                    },
                    plotOptions: {
                        pie: {
                            donut: {
                                labels: {
                                    show: true,
                                    name: {
                                        show: true,
                                        fontSize: '22px',
                                        fontFamily: 'Rubik',
                                        color: '#dfsda',
                                        offsetY: -10
                                    },
                                    value: {
                                        show: true,
                                        fontSize: '16px',
                                        fontFamily: 'Helvetica, Arial, sans-serif',
                                        color: undefined,
                                        offsetY: 0,
                                            formatter: function (val) {
                                                return val
                                            }
                                    },
                                    total: {
                                        show: true,
                                        label: 'Total',
                                        color: '#373d3f',
                                        formatter: function (w) {
                                            return w.globals.seriesTotals.reduce((a, b) => a + b);
                                        }
                                    },
                                }
                            }
                        },
                    },
                    legend: {
                        position: 'left',
                        offsetY: 20,
                        height: 230,
                         formatter: function(label, opts) {
                            return label + " - " + opts.w.globals.series[opts.seriesIndex]
                        }
                    }
                },
                seriesSecond: [],
                payroll_date: {},
			}
		},
		async mounted(){
            if(this.$route.params.data) {
				this.payroll_date = this.$route.params.data
                this.getDashboardData();
			}
            setTimeout(() => {
				var title = this.$t('salary-navbar.dashboard');
				this.$root.$emit("navbar_title", title);
			}, 0)
            
            
            this.$root.$on('refreshSalaryIndex', (data) => {
                this.payroll_date = data
				this.getDashboardData()
			})
		},
        beforeDestroy() {
			this.$root.$off('refreshSalaryIndex')
        },

		methods: {
            async getDashboardData() {
				await axios.get(`${this.$auth.user().instance.id}/payroll-dashboard`)
				.then(({data}) => {
                    this.dashboard = data.data
                    this.seriesFirst = []
                    this.seriesFirst.push(data.data.active_contracts_age_group['0-17'].count);
                    this.seriesFirst.push(data.data.active_contracts_age_group['18-30'].count);
                    this.seriesFirst.push(data.data.active_contracts_age_group['31-45'].count);
                    this.seriesFirst.push(data.data.active_contracts_age_group['46-65'].count);
                    this.seriesFirst.push(data.data.active_contracts_age_group['>65'].count);

                    this.seriesSecond = []
                    this.seriesSecond.push(data.data.active_contracts_count_female);
                    this.seriesSecond.push(data.data.active_contracts_count_male);
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => { 
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
            },
		}
	}
</script>